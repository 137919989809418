import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
//import Favorite from "@material-ui/icons/Favorite";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import FiberNewIcon from '@material-ui/icons/FiberNew';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

/***2020/12/24 修正 */
import CustomInput from "components/CustomInput/CustomInput.js";
/* MAINSKILL */
import mainskill1 from "assets/img/examples/UnrealEngine4.png";
import mainskill2 from "assets/img/examples/VisualStudio2019.png";
import mainskill3 from "assets/img/examples/React.png";
import mainskill4 from "assets/img/examples/csha.png";
/* SUBSKILL */
import subskill1 from "assets/img/examples/cplus.png";
import subskill2 from "assets/img/examples/SQL.png";
import subskill3 from "assets/img/examples/Octocat.png";
import subskill4 from "assets/img/examples/Java.png";
import subskill5 from "assets/img/examples/Magascans.png";
import subskill6 from "assets/img/examples/Excel.png";
import subskill7 from "assets/img/examples/Slack.png";
import subskill8 from "assets/img/examples/sakuraediter.png";
import subskill9 from "assets/img/examples/aws.png";
import subskill10 from "assets/img/examples/vscode.png";

import profile from "assets/img/faces/ProfileImage.png";
//import profile2 from "assets/img/faces/christian.jpg";

/*
import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
*/
/* Work */
import work1 from "assets/img/examples/NoWar.jpg";
import work2 from "assets/img/examples/Puti.jpg";
import work4 from "assets/img/examples/Team4.jpg";
import work5 from "assets/img/examples/UE_Toyama.png";
import work6 from "assets/img/examples/32Tokku.png";
import work7 from "assets/img/examples/Work7.png";
import work8 from "assets/img/examples/Work8.png";
import work9 from "assets/img/examples/Work9.jpg";

import work3 from "assets/img/examples/RainDrop.jpg";



/* Twitterの埋め込みの有効化 */
import { TwitterTweetEmbed } from 'react-twitter-embed';
/*2020/12/24 修正 ***/


import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { BusinessCenter } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="夏隣　ポートフォリオ（KARIN's PORTFOLIO）"
        //rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      {/*<Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      */}
      <Parallax small filter image={require("assets/img/bg10.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>夏隣（KARIN）</h3>
                    <h6>INDIEGAME PROGRAMMER / TA</h6>
                    
                    <Button justIcon link className={classes.margin5}>    
                      <Button
                        href="https://twitter.com/tyawandani"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                      >
                        <i className={classes.socialIcons + " fab fa-twitter"} />
                    </Button>
                    <Button
                      href="https://www.youtube.com/@karin9818/videos"
                      target="_blank"
                      color="transparent"
                      className={classes.navLink}
                    >
                      <i className={classes.socialIcons + " fas fa-play"} />
                    </Button>

                    </Button>
                    {/*　2020コメントアウト
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                    */} 
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                個人ゲーム開発者。１にアニメ、２にゲーム、３に漫画のオタク。同人文化が大好きで即売会に遊びにいく{" "}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "MAINSKILL",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={4} sm={4} md={12}>
                            <p>メインスキルをご紹介します</p>
                            <br /> <br /> <br /> 
                          </GridItem>
                          
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={mainskill1}
                              className={navImageClasses}
                            />
                            <p>UnrealEngine4〜5</p>
                            <p>最も愛用しています。
                              <br />バージョン4.20〜5.4のバージョン毎の機能追加に詳しく、ビヘイビアツリー、AnimBP、コンポーネント、インターフェース、UMG、ハード参照とソフト参照等幅広くを理解しています。
                              <br /></p>
                              <br /><br />
                            <img
                              alt="..."
                              src={mainskill3}
                              className={navImageClasses}
                            />
                            <p>React</p>
                            <p>当HomePage作成に利用。JSX,Prop,Stateなど基礎的な理解をしています。<br /><br /><br /><br /></p>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={mainskill2}
                              className={navImageClasses}
                            />
                            <p>Visual Studio</p>
                            <p>メインの開発環境です。バージョン2013〜2022の利用経験が有り、2013,2017ではC#・Basicでの産業系システム保守の実務経験
                              　2019,2022ではUnreal C++でのオートモーティブ系コンテンツ作成で利用しました。</p>
                              <br /><br /><br />
                            <img
                              alt="..."
                              src={mainskill4}
                              className={navImageClasses}
                            />
                            <p>C#</p>
                            <p>一番読みやすい言語です。C#5.0(.Net4.5),C#6.0(.Net4.6)の業務経験が有ります。Unityの使用はしていないのですが、コーディングは見やすいのでUE4で実装するときにはよくお世話になっています。</p>
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "SUBSKILL",
                      tabIcon: AddCircleRoundedIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={4} sm={4} md={12}>
                            <p>サブスキルをご紹介します</p>
                            <br /> <br /> <br /> 
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={subskill1}
                              className={navImageClasses}
                            />                            
                            <p>C++</p>
                            <p>Uクラスやテンプレートの基礎知識の他に、Unreal C++を念頭に継続的に勉強中。バージョンはC++17を使用。最適化などの際に実装などを行っている。</p>
                            <br />

                            <img
                              alt="..."
                              src={subskill2}
                              className={navImageClasses}
                            />
                            <p>Oracle</p>
                            <p>大型システム保守案件で１年半ほど使用。バージョンは18cでOracle SQL Developerを利用し管理していました。追加削除などのSQLクエリの発行〜PL/SQLの作成、性能改善など様々なことを対応しています。</p>
                            <br />
                            
                            <img
                              alt="..."
                              src={subskill3}
                              className={navImageClasses}
                            />
                            <p>Github</p>
                            <p>Gitサービスで利用。趣味程度でソース管理として利用する程度です。</p>
                            <br />
                            
                            <img
                              alt="..."
                              src={subskill4}
                              className={navImageClasses}
                            />
                            <p>AE</p>
                            <p>趣味の映像編集メインツール。UE MeetUp In Toyamaの展示作品にも使用しております。</p>
                            <img
                              alt="..."
                              src={subskill10}
                              className={navImageClasses}
                            />
                            <p> VisualStudioCode </p>
                            <p>メインテキストエディタその2。React開発の際に利用しています。</p>

                         </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={subskill6}
                              className={navImageClasses}
                            />
                            <p>Blender</p>
                            <p>お仕事ではアーティストからの要望も伺い、技術調査や検証を行っています。趣味では映像作品でのモデルの調整に使用。</p>
                            <br/>
                            
                            <img
                              alt="..."
                              src={subskill5}
                              className={navImageClasses}
                            />
                            <p>Quixel Plugin</p>
                            <p>Megascans Plugin for Unreal Engineの利用。映像用途で使用しています。 <br/> <br/> <br/></p>
                            <br />
                            
                            <img
                              alt="..."
                              src={subskill7}
                              className={navImageClasses}
                            />
                            <p>Slack</p>
                            <p>趣味開発で使用する程度。SNSサービスでは一番好きです。</p>
                            <br />
                            
                            <img
                              alt="..."
                              src={subskill8}
                              className={navImageClasses}
                            />
                            <p>サクラエディタ</p>
                            <p>メインテキストエディタその１。ファイル検索や改行コード確認の際利用</p>
                            
                            
                            <img
                              alt="..."
                              src={subskill9}
                              className={navImageClasses}
                            />
                            <p> AWS </p>
                            <p>趣味開発で利用。S3やEC2の基本的な知識を理解しており、HPを公開する際利用しております。</p>
                            <br />
                            
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Work",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={4} sm={4} md={12}>
                            <p>これまでのお仕事</p>
                            <br /> <br /> <br /> 
                          </GridItem>
                         
                          <p><b>以下に関しては、許可なく口外及びインターネットへの転載・スクショ・録画を撮ることは固く禁じます。</b></p>
                          <GridItem xs={12} sm={12} md={8}>
                          <br /><br />
                          <br /><br />
                          <p>配信型　故障診断教育アプリケーション作成　大手自動車T様</p>
                          <p>開発:Unreal Engine4.26〜5.0</p>
                          <p>担当:開発リード・メイン開発</p>
                          <p>期間:202104~202306</p>
                          <Button
                              color="danger"
                              size="lg"
                              href="https://www.tairapromote.co.jp/XR-Solution.html"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <i className="fas fa-play" />
                            　 リンクはこちら
                          </Button>
                          <br /><br />
                          <br /><br />
                          <p>工場アプリケーション (クライアント非公開)</p>
                          <p>開発:Unreal Engine 5.1</p> 
                          <p>担当:PG・ライティング・カメラ・レベルデザイン</p>
                          <p>期間:202304〜202305</p>               
                          <br /><br />

                          <p>映像製作技術協力　(クライアント非公開)</p>
                          <p>開発:Unreal Engine 5.1</p>
                          <p>担当:デモ制作(趣味でやっていたMassAIを使用した車両群集制御デモ)</p>
                          <p>期間:202306〜202307</p>                   
                          <br /><br />
                        　
                        　※これ以降の実績はお問い合わせください。
                          </GridItem>

                       </GridContainer>
                      )
                    },
                    {
                      tabButton: "Hobby(UEonly)",
                      tabIcon: FiberNewIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={4} sm={4} md={12}>
                            <p>Unreal Engineの趣味開発をご紹介します</p>
                            <br /> <br /><br />
                            </GridItem>


                          <GridItem xs={12} sm={12} md={8}>
                          <img
                              alt="..."
                              src={work6}
                              
                              className={navImageClasses}
                          />
                          <p><b>32特区のならずもの(2023年〜　開発中)</b></p>
                          <p>糸を使った3Dアクションゲームを一人でもくもく開発中です。</p> 
                          <Button
                              color="danger"
                              size="lg"
                              href="https://youtu.be/vmGvLIHMEd4?si=Cp0rDYnpPK-o2Mbd"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-play" />
                            　 動画はこちら
                          </Button>

                          <br /><br />
                          <br /><br />
                          <br /><br />


                          <img
                              alt="..."
                              src={work4}
                              
                              className={navImageClasses}
                          />
                          <p><b>GlobalGameJam</b></p>
                          <p>敵AIとUX作ることが多めですがUnreal何でも屋です。</p>
                          <p>2023年は2.5Dのシューティングゲーム”DocDoctorPaperRun”をチーム製作しました。</p>
                          <p>メンバーのみんなわいわいできて楽しかったです</p>
                          <Button
                              color="danger"
                              size="lg"
                              href="https://v3.globalgamejam.org/users/%E8%8C%B6%E7%A2%97%E8%B0%B7%E5%A4%8F%E9%9A%A3"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-play" />
                              ３年分の作品リスト
                            </Button>
                            <br /><br />
                         
                          <Button
                              color="danger"
                              size="lg"
                              href="https://www.youtube.com/live/IUWwUcM7p-w?si=jij3fW5dMxgWiEtX"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-play" />
                            　 動画(9分30秒ぐらいから〜)
                          </Button>

                          <br /><br /> 
                          <br /><br /> 
                          <br /><br />

                          <img
                              alt="..."
                              src={work9}
                              
                              className={navImageClasses}
                          />
                          <p><b>駅下の神様</b></p>  
                          <p>UE Toyama 2023 MeetUp 動画展示</p>
                          <p>UE×テキストアニメーションのショート動画</p>
                          <p>実際の駅をモデルにレベルを製作し、アセット製作の一部をUnrealEngineのモデリング機能で作成。</p>
                          {/* <GridItem xs={4} sm={4} md={12}>*/}
                            <GridItem xs={8}>
                                <TwitterTweetEmbed tweetId={"1730496514693493073"} />
                            </GridItem>
                            

                            <Button
                              color="danger"
                              size="lg"
                              href="https://youtu.be/-7R7QdpVvJQ?si=gP-Kh1yHZSUyuhgI"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                            <i className="fas fa-play" />
                              　 　 動画はこちら
                            </Button>
                            <br /><br />
                            <br /><br />
                            <br /><br />

                          <img
                              alt="..."
                              src={work7}
                              
                              className={navImageClasses}
                          />
                          <p><b>ハダカのおじさん(仮)　個展(2023年) 制作協力</b></p>  
                          <Button
                              color="danger"
                              size="lg"
                              href="https://artsticker.app/events/2065"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-play" />
                            　 　イベントはこちら
                            </Button>                        
                          
                            <br /><br />
                            <br /><br />
                            <br /><br />
                        <img
                              alt="..."
                              src={work8}
                              
                              className={navImageClasses}
                          />
                          <p><b>ヒストリア様イベント　ぷちコン投稿作品(2023年)</b></p>
                          <p>第20回投稿作品</p>
                          <Button
                              color="danger"
                              size="lg"
                              href="https://youtu.be/bjrQM4AsTyE?si=vvHKLv_WaK6hC6u1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-play" />
                              　  動画はこちら
                          </Button> 
                          <br /><br />
                            <br /><br />
                            <br /><br />

                          

                          </GridItem>

                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "CONTACT",
                      tabIcon: MailOutlineRoundedIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8}>
                          <p>お仕事依頼など下記サービスにてご連絡ください</p> 
                          <p>※返信には1〜2週間程度いただく場合がございます。</p> 
                          <p>※連絡が遅い場合はTwitterDMまでいただけると対応いたします</p> 
                           
                          {/*<Button color="google">*/}
                          <Button
                            href="https://mail.google.com/mail/u/0/?view=cm&to=tyawandanikarin009@gmail.com"
                            target="_blank"
                            color="google"
                            className={classes.navLink}
                          >
                            <i className={classes.socials + " fab fa-google-plus-g"} />
                            Gmail
                          </Button>
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
